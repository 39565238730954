dmx.Component('calendar-source-base', {

    constructor: function(node, parent, source) {
        if (parent instanceof dmx.Component('calendar')) {
            this.calendar = parent.calendar;
        } else {
            console.error('calendar-source can only be child of calendar component.');
        }

        dmx.BaseComponent.call(this, node, parent);
    },

    initialData: {
        id: null,
        events: []
    },

    attributes: {
        'color': {
            type: String,
            default: null
        },

        'background-color': {
            type: String,
            default: null
        },

        'border-color': {
            type: String,
            default: null
        },

        'text-color': {
            type: String,
            default: null
        },

        'class-name': {
            type: String,
            default: null
        },

        'editable': {
            type: Boolean,
            default: null
        },

        'overlap': {
            type: Boolean,
            default: false
        },

        'bs4-tooltip': {
            type: Boolean,
            default: false
        },

        'bs4-tooltip-placement': {
            type: String, // expression
            default: '"top"'
        },

        'bs4-tooltip-title': {
            type: String, // expression
            default: 'event.extendedProps.description || event.title'
        }
    },
    
    methods: {
        refetch: function() {
            this.refetch();
        }
    },

    render: function() {
        if (!this.calendar) return;
        this.$parse();
        this.set('id', this.name);
    },

    refetch: function() {
        this.calendar.getEventSourceById(this.name).refetch();
    },

    toCamelCase: function(str) {
        return str.replace(/-(\w)/g, function(a, b) {
            return b.toUpperCase();
        });
    }

});