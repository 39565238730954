dmx.Component('calendar-event', {

    constructor: function(node, parent, source) {
        if (!(parent instanceof dmx.Component('calendar-source'))) {
            console.error('calendar-event can only be child of calendar-source component.');
        }

        dmx.BaseComponent.call(this, node, parent);
    },

    attributes: {
        'title': {
            type: String,
            default: null
        },

        'url': {
            type: String,
            default: null
        },

        'start': {
            type: String,
            default: null
        },

        'end': {
            type: String,
            default: null
        },

        'all-day': {
            type: Boolean,
            default: false
        },

        'days-of-week': { // For defining a simple recurring event
            type: Array,
            default: null
        },

        'start-time': { // For defining a simple recurring event
            type: String,
            default: null
        },

        'end-time': { // For defining a simple recurring event
            type: String,
            default: null
        },

        'start-recur': { // For defining a simple recurring event
            type: String,
            default: null
        },

        'end-recur': { // For defining a simple recurring event
            type: String,
            default: null
        },

        'group-id': {
            type: String,
            default: null
        },

        'rendering': {
            type: String, // background, inverse-background
            default: null
        },

        'editable': {
            type: Boolean,
            default: null
        },

        'color': {
            type: String,
            default: null
        },

        'background-color': {
            type: String,
            default: null
        },

        'border-color': {
            type: String,
            default: null
        },

        'text-color': {
            type: String,
            default: null
        },

        'class-name': {
            type: String,
            default: null
        },

        'extended-props': {
            type: Object,
            default: null
        }
    },

    $parseAttributes: function(node) {
        var self = this;

        dmx.BaseComponent.prototype.$parseAttributes.call(this, node);

        // constraint custom parse
        this.parseAttribute(node, 'constraint').forEach(function(attr) {
            if (attr.modifiers['business-hours']) {
                // special case for businessHours modifier
                self.props.constraint = 'businessHours';
            } else {
                if (attr.binding) {
                    // dynamic attribute
                    self.$addBinding(attr.value, function(value) {
                        self.props.constraint = value;
                    });
                } else {
                    // static attribute
                    self.props.constraint = attr.value;
                }
            }

            // we need to remove the attribute so that the default parser doesn't parse it
            node.removeAttribute(attr.fullName);
        });
    },

    update: function(props) {
        if (!dmx.equal(props, this.props)) {
            if (this.parent.refetch) {
                this.parent.refetch();
            }
        }
    },

    // special parse required for constraint attribute
    // supports static/dynamic attributes
    parseAttribute: function(node, attrName) {
        var attributes = [];
        var re = new RegExp('^(dmx\\-bind:)?' + attrName.replace(/[-[\]{}()*+?.,\^$|#\s]/g, '\\$&'), 'i');

        if (node.nodeType == 1) {
            for (var i = 0; i < node.attributes.length; i++) {
                var attribute = node.attributes[i];

                if (attribute && attribute.specified && re.test(attribute.name)) {
                    var name = attribute.name;
                    var argument = null;
                    var modifiers = {};

                    name = name.replace(/^dmx\-bind:/i, '');

                    name.split('.').forEach(function(part, i) {
                        if (i === 0) {
                            name = part;
                        } else {
                            var pos = part.indexOf(':');
                            if (pos > 0) {
                                modifiers[part.substr(0, pos)] = part.substr(pos + 1);
                            } else {
                                modifiers[part] = true;
                            }
                        }
                    });

                    var pos = name.indexOf(':');
                    if (pos > 0) {
                        argument = name.substr(pos + 1);
                        name = name.substr(0, pos);
                    }

                    attributes.push({
                        name: name,
                        binding: attribute.name.indexOf('dmx-bind:') === 0,
                        fullName: attribute.name,
                        value: attribute.value,
                        argument: argument,
                        modifiers: modifiers
                    });
                }
            }
        }

        return attributes;
    }

});